<template>
  <main class="page-not-found">
    <div class="error-main-container">
      <img src="@/assets/media/career/003-ufo.svg" />
      <h1>Page not found</h1>
      <p
        class="error-description"
      >We're sorry, the page you requested could not be found. Please redirect back to home.</p>
      <a href="#" @click="returnToHome">Go Back to Home</a>
    </div>
  </main>
</template>

<script>
export default {
  methods: {
    returnToHome() {
      window.location = "/";
    }
  }
};
</script>

<style>
</style>